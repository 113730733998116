import { css } from 'linaria';
import React from 'react';
import { filterName } from './shared/styles';
import { useBooleanFilter } from '@jetshop/core/hooks/Filters/useBooleanFilter';
import Checkbox from '../../../../components/ui/Checkbox';

export const filtersStyle = css`
  list-style: none;
  li button {
    padding: 1em;
    width: 100%;
    display: flex;
    background: transparent;
    border-top: 1px solid white;
    background: #f0f0f0;
    justify-content: space-between;
    align-items: center;
    font-size: 0.875rem;
    span {
      /* result count */
      font-size: 0.75em;
    }
  }
`;

export const BooleanAccordion = ({
  filters,
  accordionProps,
  startingIdx = 0
}) => {
  if (!filters) return null;

  const listFilters = filters.filter(
    filter => filter.__typename === 'BooleanFilter'
  );

  return (
    <>
      {listFilters.map((filter, idx) => (
        <BoolFilter
          key={filter.id}
          filter={filter}
          idx={idx}
          accordionProps={accordionProps}
        />
      ))}
    </>
  );
};

function BoolFilter({ filter, idx, accordionProps, startingIdx }) {
  const { apply } = useBooleanFilter({ filter });

  return (
    <>
      <Checkbox
        fill
        className={filterName}
        value={filter.value}
        checked={filter.value}
        onChange={(e, checked) => {
          apply({ value: checked });
        }}
      >
        {filter.name}
      </Checkbox>
    </>
  );
}
