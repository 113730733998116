import React from 'react';
import { styled } from 'linaria/react';
import { ReactComponent as Check } from '../../assets/svg/Check.svg';
import { theme } from '../Theme';

const CheckboxWrapper = styled('label')`
  justify-content: flex-start;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5em;
  cursor: pointer;
  min-height: 2rem;

  svg {
    width: 1.25rem;
    height: 1.25rem;
    border: 1px solid ${theme.colorScheme.darkgrey};
    box-sizing: content-box;
    padding: 0.15em;
    box-sizing: border-box;
    path {
      fill: transparent;
    }
  }
  input:checked + svg {
    path {
      fill: ${theme.colorScheme.black};
    }
  }
  .Checkbox-input {
    position: absolute;
    left: -999rem;
  }
`;

const Checkbox = ({
  name,
  value,
  checked,
  defaultChecked,
  label,
  children,
  onChange,
  className
}) => {
  const handleChange = e => {
    if (typeof onChange === 'function') {
      onChange(e, e.target.checked);
    }
  };
  return (
    <CheckboxWrapper className={className}>
      <input
        onChange={handleChange}
        type="checkbox"
        checked={checked}
        defaultChecked={defaultChecked}
        name={name}
        value={value}
        className="Checkbox-input"
      />
      <Check className="Checkbox-icon" />
      <span className="Checkbox-text">{label || children}</span>
    </CheckboxWrapper>
  );
};

export default Checkbox;
