import { useFilters } from '@jetshop/core/hooks/Filters/useFilters';
import { useListFilter } from '@jetshop/core/hooks/Filters/useListFilter';
import t from '@jetshop/intl';

import React from 'react';
import { styled } from 'linaria/react';
import Button from '../../../components/ui/Button';
import ToggleButton from '../../../components/ui/ToggleButton';
import { BooleanFilter } from './BooleanFilters';

export const FilterGroup = styled('div')`
  font-size: 0.875rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.5rem;
`;
export const AppliedFilterButton = styled(ToggleButton)``;

export const FilterWrapper = styled('aside')`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  margin-top: 0.5rem;
`;

export const ClearButton = styled(Button)`
  background: none;
  text-decoration: underline;
  text-decoration: none;
`;

export function ActiveFilters({ filters }) {
  const { clearAllFilters } = useFilters();

  if (!filters) return null;

  const activeListFilters = filters.filter(listFilter => {
    return listFilter.hasActiveItems || listFilter.value === true;
  });

  if (activeListFilters.length === 0) return null;

  return (
    <FilterWrapper>
      {activeListFilters.map(listFilter => {
        return (
          <FilterGroup key={listFilter.id}>
            {listFilter?.items ? (
              listFilter.items
                .filter(item => item.isActive)
                .map(listFilterItem => (
                  <ActiveListFilterItem
                    item={listFilterItem}
                    listFilter={listFilter}
                    key={listFilterItem.value}
                  />
                ))
            ) : (
              <BooleanFilter
                outlined
                filter={listFilter}
                key={listFilter.text}
              />
            )}
          </FilterGroup>
        );
      })}
      <ClearButton hideArrow secondary type="button" onClick={clearAllFilters}>
        {t('Clear all filters')}
      </ClearButton>
    </FilterWrapper>
  );
}

function ActiveListFilterItem({ item, listFilter }) {
  const { apply } = useListFilter({ filter: listFilter });
  return (
    <AppliedFilterButton
      outlined
      selected
      type="button"
      key={item.value}
      onClick={() => {
        apply({ value: item.value });
      }}
    >
      {item.text}
    </AppliedFilterButton>
  );
}
