import t from '@jetshop/intl';
import DropdownMenu from '../../../components/ui/DropdownMenu/DropdownMenu';
import * as React from 'react';
import { useSortOrder } from '@jetshop/core/hooks/Filters/useSortOrder';
import ErrorBoundary from '@jetshop/ui/ErrorBoundary/Generic';
import PassThrough from '@jetshop/ui/ErrorBoundary/PassThrough';

export const SortOrders = ({ sortOrders }) => {
  if (sortOrders.length === 0) return null;
  return (
    <ErrorBoundary component={PassThrough}>
      <SortOrderDropdown sortOrders={sortOrders} />
    </ErrorBoundary>
  );
};

function SortOrderDropdown({ sortOrders }) {
  const {
    mappedSortOrder,
    changeSortOrder,
    sortOrder,
    direction
  } = useSortOrder({ sortOrders });

  // Update the direction of a specific item based on the value (ugly hack)
  const updatedMappedSortOrder = mappedSortOrder.map(item => {
    if (item.value === 'BESTSELLER') {
      return {
        ...item,
        direction: 'DESCENDING'
      };
    }
    return item;
  });

  const activeItem = updatedMappedSortOrder.find(
    item => item.direction === direction && item.value === sortOrder
  );

  return !updatedMappedSortOrder?.length ? null : (
    <DropdownMenu
      id={'sortby'}
      inline
      label={t('Sort By')}
      placeholder={t('Sort By')}
      onSelect={val => {
        const [value, direction] = val.split(':') || [];
        changeSortOrder(value, direction);
      }}
      value={activeItem && `${activeItem.value}:${activeItem.direction}`}
      options={updatedMappedSortOrder.map(({ text, value, direction }) => ({
        text,
        value: `${value}:${direction}`
      }))}
    />
  );
}
