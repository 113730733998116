import React from 'react';
import { ReactComponent as Cross } from '@jetshop/ui/svg/Cross.svg';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import { theme } from '../Theme';
import { toRem } from '../../helpers';

const StyledToggleButton = styled('button')`
  border: 2px solid ${theme.colorScheme.black};
  border-radius: ${toRem(100)};
  white-space: nowrap;
  background: ${theme.colorScheme.white};
  padding: 0.5em 1em;
  font-size: 0.875em;
  line-height: 1.125em;
  font-weight: ${theme.fontWeights.bold};
  justify-content: space-between;
  &.fill {
    border-radius: 0;
    font-size: inherit;
    display: block;
  }
  &.selected {
    background: ${theme.colorScheme.black};
    color: ${theme.colorScheme.white};
  }
  &.outlined {
    background: ${theme.colorScheme.white};
    color: ${theme.colorScheme.black};
    border: 1px solid ${theme.colorScheme.lightgrey};

    .ToggleButton-icon {
      background: ${theme.colorScheme.mediumgrey};
      border-radius: 2rem;
      padding: 0.25em;
      margin-right: -0.25em;
    }
  }
  .ToggleButton-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5em;
  }
  .ToggleButton-icon {
    opacity: 1;
    width: 1em;
    height: 1em;
    box-sizing: content-box;
  }
`;
const ToggleButton = ({ selected, children, outlined, fill, ...rest }) => (
  <StyledToggleButton
    hideArrow
    {...rest}
    className={cx(
      selected && 'selected',
      outlined && 'outlined',
      fill && 'fill'
    )}
  >
    <div className="ToggleButton-content">
      <span>{children}</span>
      {selected && <Cross className="ToggleButton-icon" />}
    </div>
  </StyledToggleButton>
);
export default ToggleButton;
