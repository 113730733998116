import { useListFilter } from '@jetshop/core/hooks/Filters/useListFilter';
import { useIntl } from '@jetshop/intl';
import ErrorBoundary from '@jetshop/ui/ErrorBoundary/Generic';
import PassThrough from '@jetshop/ui/ErrorBoundary/PassThrough';
import React from 'react';
import DropdownMenu from '../../../components/ui/DropdownMenu/DropdownMenu';

export function ListFilters({ filters }) {
  const listFilters = filters.filter(
    filter => filter.__typename === 'ListFilter'
  );

  if (listFilters.length === 0) return null;

  return listFilters.map(filter => (
    <ErrorBoundary component={PassThrough} key={filter.id}>
      <ListFilter filter={filter} />
    </ErrorBoundary>
  ));
}

function ListFilter({ filter }) {
  const { apply, clear } = useListFilter({ filter });
  const onSelect = value => () => apply({ value });
  const t = useIntl();

  return (
    <DropdownMenu
      id={filter.id || filter.name}
      placeholder={filter.name}
      onSelect={value => {
        if (value === 'clear') {
          clear();
          return;
        }
        onSelect(value).call();
      }}
      value={false}
      options={[
        ...filter.items.map(item => ({
          text: `${item.text} (${item.resultCount})`,
          value: item.value,
          disabled: item.resultCount === 0
        })),
        filter.hasActiveItems && {
          text: t('Clear filter'),
          value: 'clear'
        }
      ].filter(Boolean)}
    />
  );
}
