import { Above, Below } from '@jetshop/ui/Breakpoints';
import Drawer, { DrawerTarget, DrawerTrigger } from '@jetshop/ui/Modal/Drawer';
import { SortOrders } from './SortOrders';
import { styled } from 'linaria/react';
import React, { Component } from 'react';
import { ActiveFilters } from './ActiveFilters';
import { BooleanFilters } from './BooleanFilters';
import FilterDrawer from './FilterDrawer';
import { ListFilters } from './ListFilters';
import { MultiListFilters } from './MultiListFilters';
import { RangeFilters } from './RangeFilters';
import FilterButton from './Mobile/FilterButton';

const Filters = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

const FiltersSectionWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  justify-content: space-between;
  .boolean-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
`;

const FiltersWapper = styled('div')``;
const BoolSortWrapper = styled('div')``;

class Filter extends Component {
  render() {
    const { filters } = this.props;
    const sortOrders = this.props?.sortOrders ?? [];
    return (
      <>
        <Above breakpoint="md">
          {matches =>
            matches && (
              <>
                <FiltersSectionWrapper className="above-md">
                  <FiltersWapper>
                    <Filters>
                      <ListFilters filters={filters} />
                      <RangeFilters filters={filters} />
                      <div className="boolean-wrapper">
                        <BooleanFilters filters={filters} />
                      </div>
                    </Filters>
                    <Filters>
                      <MultiListFilters filters={filters} />
                    </Filters>
                  </FiltersWapper>
                  <BoolSortWrapper>
                    <Filters>
                      <SortOrders sortOrders={sortOrders} />
                    </Filters>
                  </BoolSortWrapper>
                </FiltersSectionWrapper>
                <ActiveFilters className="above-md" filters={filters} />
              </>
            )
          }
        </Above>
        <Below breakpoint="md">
          {matches =>
            matches && (
              <>
                <DrawerTrigger className="below-md" id="filterDrawer">
                  {({ showTarget }) => (
                    <FilterButton showTarget={showTarget} filters={filters} />
                  )}
                </DrawerTrigger>

                <DrawerTarget className="below-md" id="filterDrawer">
                  {({ hideTarget, isOpen }) => (
                    <Drawer isOpen={isOpen} right>
                      <FilterDrawer
                        close={hideTarget}
                        filters={filters}
                        sortOrders={sortOrders}
                      />
                    </Drawer>
                  )}
                </DrawerTarget>
              </>
            )
          }
        </Below>
      </>
    );
  }
}

export default Filter;
