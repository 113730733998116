import { Intl } from '@jetshop/intl';
import Accordion from '@jetshop/ui/Accordion/Accordion';
import React from 'react';
import { styled } from 'linaria/react';
import { BooleanAccordion } from './Mobile/BooleanAccordion';
import { ListAccordion } from './Mobile/ListAccordion';
import { RangeAccordion } from './Mobile/RangeAccordion';
import { SortOrderAccordion } from './Mobile/SortOrderAccordion';
import { useFilters } from '@jetshop/core/hooks/Filters/useFilters';
import { ClearButton } from './ActiveFilters';
import t from '@jetshop/intl';
import Button from '../../../components/ui/Button';

const FilterWrapper = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const Title = styled('h1')`
  color: black;
  font-size: 1.125rem;
  text-align: center;
  padding: 1.5rem;
  border-bottom: 1px solid #e8e8e8;
  font-weight: 600;
`;

const Wrapper = styled('div')`
  button {
    padding: 0 1rem;
    border-width: 1px 0 0 0;
  }
`;

const ButtonContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  > * {
    flex-grow: 1;
    padding: 1rem;
    border-width: 1px;
  }
  > *:not(:first-child) {
    margin-left: -1px;
  }
`;

const FilterDrawer = ({ filters, sortOrders, close, ...props }) => {
  const { clearAllFilters } = useFilters();
  const isActiveListFilter = filters.some(listFilter => {
    return listFilter.hasActiveItems || listFilter.value === true;
  });

  const listFilters = (filters || []).filter(
    filter => filter.__typename === 'ListFilter'
  );

  const rangeFilters = (filters || []).filter(
    filter => filter.__typename === 'NumericRangeFilter'
  );

  const isFilters = !!filters;
  const isSortOrders = sortOrders.length > 0;

  return (
    <FilterWrapper {...props}>
      {(isFilters || isSortOrders) && (
        <>
          <Wrapper>
            <Title>
              <Intl>
                {t =>
                  isFilters && isSortOrders
                    ? `${t('Filter')} & ${t('Sort by')}`
                    : isFilters
                    ? t('Filter')
                    : t('Sort by')
                }
              </Intl>
            </Title>
            <Accordion single>
              {accordionProps => (
                <>
                  {isFilters && (
                    <>
                      <ListAccordion
                        filters={filters}
                        accordionProps={accordionProps}
                      />
                      <RangeAccordion
                        filters={filters}
                        accordionProps={accordionProps}
                        startingIdx={listFilters.length}
                      />
                      <BooleanAccordion
                        accordionProps={accordionProps}
                        filters={filters}
                      />
                    </>
                  )}
                  {isSortOrders && (
                    <SortOrderAccordion
                      sortOrders={sortOrders}
                      accordionProps={accordionProps}
                      startingIdx={listFilters.length + rangeFilters.length}
                    />
                  )}
                </>
              )}
            </Accordion>
          </Wrapper>

          <ButtonContainer>
            <Button secondary hideArrow rounded={false} onClick={close}>
              {t('Apply filters')}
            </Button>
            {isActiveListFilter && (
              <ClearButton
                hideArrow
                rounded={false}
                secondary
                type="button"
                onClick={clearAllFilters}
              >
                {t('Clear all filters')}
              </ClearButton>
            )}
          </ButtonContainer>
        </>
      )}
    </FilterWrapper>
  );
};
export default FilterDrawer;
