import React from 'react';
import ToggleButton from '../../../components/ui/ToggleButton';
import { useBooleanFilter } from '@jetshop/core/hooks/Filters/useBooleanFilter';

export function BooleanFilters({ filters }) {
  if (!filters) return null;

  const boolFilters = filters.filter(
    filter => filter.__typename === 'BooleanFilter'
  );

  if (boolFilters.length === 0) return null;

  return boolFilters.map(filter => (
    <BooleanFilter filter={filter} key={filter.id} />
  ));
}

export function BooleanFilter({ filter, outlined }) {
  const { apply } = useBooleanFilter({ filter });
  return (
    <ToggleButton
      outlined={outlined}
      selected={filter.value}
      onClick={e => {
        apply({ value: !filter.value });
      }}
    >
      {filter.name}
    </ToggleButton>
  );
}
